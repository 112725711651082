body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.highlight-gradient {
  background: rgb(51, 153, 153) !important;
  background: -moz-linear-gradient(
    90deg,
    rgb(51, 153, 153) 0%,
    rgba(51, 153, 153, 0.20634191176470584) 43%,
    rgba(2, 0, 36, 0) 100%
  ) !important;
  background: -webkit-linear-gradient(
    90deg,
    rgb(51, 153, 153) 0%,
    rgba(51, 153, 153, 0.20634191176470584) 43%,
    rgba(2, 0, 36, 0) 100%
  ) !important;
  background: linear-gradient(
    90deg,
    rgb(51, 153, 153) 0%,
    rgba(51, 153, 153, 0.20634191176470584) 43%,
    rgba(2, 0, 36, 0) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00d4ff",endColorstr="#020024",GradientType=1);
}

/* .highlight-gradient>tr[id="self"] {
  background: transparent;
} */

/* Shadow for Segments */
.breathing-shadow {
  border: 1px solid #ebe9e9 !important;
  border-radius: "20px" !important;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07),
    0px 10px 30px rgba(0, 0, 0, 0.07) !important;
}
