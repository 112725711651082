h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
}

.ui {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}

.capital-text-field {
  text-transform: none;
}

.noto-sans-regular {
  font-family: "Noto Sans", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-variation-settings: "wdth" 100 !important;
}

.noto-sans-light {
  font-family: "Noto Sans", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 300 !important;
  font-style: normal !important;
  color: grey;
  font-variation-settings: "wdth" 100 !important;
}

.poppins-light {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important;
}

.poppins-regular {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.poppins-bold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-style: normal !important;
}

@media only screen and (max-width: 767px) {
  .small-text {
    font-size: 0.8em !important;
  }
  .medium-text {
    font-size: 1.0em !important;
  }
  .large-text {
    font-size: 1.2em !important;
  }
  .title-small {
    font-size: 0.7em !important;

    color: #4c4e50 !important;
  }
  .title-medium {
    font-size: 1em !important;

    color: #4c4e50 !important;
  }
  .title-large {
    font-size: 1.24em !important;

    color: #4c4e50 !important;
  }
  .heading-text {
    font-size: 1.5em !important;

    color: #4c4e50 !important;
  }
}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small-text {
    font-size: 0.8em !important;
  }
  .medium-text {
    font-size: 1.0em !important;
  }
  .large-text {
    font-size: 1.2em !important;
  }
  .title-small {
    font-size: 0.7em !important;

    color: #4c4e50 !important;
  }
  .title-medium {
    font-size: 1em !important;

    color: #4c4e50 !important;
  }
  .title-large {
    font-size: 1.35em !important;

    color: #4c4e50 !important;
  }
  .heading-text {
    font-size: 1.7em !important;

    color: #4c4e50 !important;
  }
}

/* Computer/Desktop Monitor */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .small-text {
    font-size: 1.0em !important;
  }
  .medium-text {
    font-size: 1.2em !important;
  }
  .large-text {
    font-size: 1.4em !important;
  }
  .title-small {
    font-size: 0.9em !important;
    color: #4c4e50 !important;
  }
  .title-medium {
    font-size: 1.2em !important;

    color: #4c4e50 !important;
  }
  .title-large {
    font-size: 1.5em !important;

    color: #4c4e50 !important;
  }
  .heading-text {
    font-size: 2em !important;

    color: #4c4e50 !important;
  }
}

/* Large Monitor */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .small-text {
    font-size: 1.0em !important;
  }
  .medium-text {
    font-size: 1.2em !important;
  }
  .large-text {
    font-size: 1.4em !important;
  }
  .title-small {
    font-size: 1em !important;

    color: #4c4e50 !important;
  }
  .title-medium {
    font-size: 1.3em !important;

    color: #4c4e50 !important;
  }
  .title-large {
    font-size: 1.9em !important;

    color: #4c4e50 !important;
  }
  .heading-text {
    font-size: 2.5em !important;

    color: #4c4e50 !important;
  }
}

/* WideScreen Monitor */
@media only screen and (min-width: 1920px) {
  .small-text {
    font-size: 1em !important;
  }
  .medium-text {
    font-size: 1.2em !important;
  }
  .large-text {
    font-size: 1.5em !important;
  }
  .title-small {
    font-size: 1.1em !important;

    color: #4c4e50 !important;
  }
  .title-medium {
    font-size: 1.4em !important;

    color: #4c4e50 !important;
  }
  .title-large {
    font-size: 1.9em !important;

    color: #4c4e50 !important;
  }
  .heading-text {
    font-size: 2.5em !important;

    color: #4c4e50 !important;
  }
}
