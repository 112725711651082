.react-calendar-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.react-calendar-size {
  width: 100% !important;
}

.react-calendar__tile{
  width: 100% !important;
  height: 7vh !important;
}


.react-calendar__tile.highlight {
  background: #33999977;
  color: white;
  border-radius: 10%;
  padding:1% !important;
  border: 1px solid white;
}

.react-calendar__tile.selected {
  background: #339999 !important;
}

.react-calendar__tile:hover {
  background-color: #cce7ff; 
  color: #000; 
}

/* 
.react-calendar__tile--active {
  background-color: #007BFF; 
  color: white;
}

.react-calendar__tile--now {
  background-color: #FB6E67; 
  color: white; 
}
.react-calendar__tile--now:hover {
  background-color: #FB6E67 !important; 
  color: white; 
} */



.react-calendar__tile--disabled {
  background-color: transparent !important;
  opacity: 0.5;  
}


.react-calendar__tile--disabled {
  border: 1px solid #e0e0e0;
  box-shadow: none;
}

.react-calendar__tile--disabled:hover {
  background-color: transparent !important; 
  color: #b0b0b0; 
}


